import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Navbar from "../../partials/Navbar";
import Sidebar from '../../partials/Sidebar'
// import Header from '../../Components/Header'
import ReactDatatable from '@ashvin27/react-datatable';
import EditSocaillink from './editsociallink';
import { IMG_URL } from '../../config/env';
import toast, { Toaster } from 'react-hot-toast';
import { Modal } from 'react-bootstrap';


//Actions

import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { getsociallists } from '../../../actions/cmsAxios';


function Sociallinklist() {
    // edit modal
    const [showEdit, setShowEdit] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    //   delete modal

    const [showDelete, setShowDelete] = useState(false);

    const [getFee, setGetFee] = useState([])
    const [deleteId, setDeleteId] = useState('')
    const [editData, setEditData] = useState({})



    const [active, setActive] = useState('buy')
    const state = {
        records: [
            {
                "feename": "LEO",
                "bnbvalue": "5",
                "tokenvalue": "0"
            },
            {
                "feename": "DAS",
                "bnbvalue": "2",
                "tokenvalue": "1"
            },



        ]
    }

    const columns = [
   
        {
            key: "feeName",
            text: "Title",
            className: "feename",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                   <div>{record.title}</div>
                )
            },
        },
        {
            key: "currencyvalue",
            text: "Link",
            className: "bnbvalue",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <div>{record.link}</div>
                )
            },
        },
        {
            key: "tokenvalue",
            text: "Image",
            className: "bnbvalue",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <div><img src={`${IMG_URL}/sociallink/${record.image}`} width={25} /></div>
                  )
            },

        },
        {
            key: "delete",
            text: "Delete",
            className: "NFT NAME",
            align: "left",
            sortable: false,
            cell : record =>
            <div><button className='btn allbtn allbtns btn-primary' onClick={()=>deleteSocial(record,"delete")} >DELETE</button></div>
           
        
          },
        {
            key: "action",
            text: "Action",
            className: "activity",
            align: "center",
            sortable: false,
            cell: (record) => {
                {console.log("sdfgsdg",record);}
                return (
                    <div className='d-flex justify-content-center align-items-center gap-2'>
                        <button className='cmn_plain_btn btn btn-primary btn-sm' onClick={() => { setEditData(record); handleShowEdit() }}>
                            {/* <img src={require('../../assets/images/editer.svg').default}
                            className='img-fluid table_activity_img' />  */}
                            <i class="fa fa-edit"></i>
                            </button>

                    </div>
                )
            }
        },
    ];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Emailtemplates",
        no_data_text: "No Email Templates found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: true,
        show_info: false,
    };

    const extraButtons = [
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
        },
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
            onDoubleClick: (event) => {
                console.log("doubleClick")
            }
        },
    ]


    useEffect(() => {
        GetCmscontents()
    }, [])


    const GetCmscontents = async () => {
        let get = await getsociallists();
        console.log("getCmslists", get,get?.data);
        if (get?.status == true) {
            setGetFee(get?.data);
            console.log("getFee_getFee", get?.data)
        }
    }


    const deleteSocial = async(record,filter)=>{
        console.log("daaata",record,filter)
        var payload = {
            id:record._id,
            action:filter
        }
       
    
        var resp = await getsociallists(payload);
        console.log("payloads",payload);
        if(resp?.status){
            toast.success(resp.msg)
            setTimeout(function(){ 
                GetCmscontents();
            }, 2000);
            
          } 
          else return toast.error(resp.msg)
    }


    return (
        <>
     

            <Navbar />
            <div className="d-flex" id="wrapper">
            <Sidebar/>
            <div id="page-content-wrapper">
                <div className="container-fluid">
                    <NavLink to='/addsociallink' className='sidebar_links'>
                        <button className='orange_small_primary btn btn-primary mt-3'>Add Sociallink</button>
                    </NavLink>
                    <div className='common_page_scroller mt-4'>
                    <div className='exchange_table_holder dashboard_box rounded-3'>
                        <ReactDatatable
                            config={config}
                            records={getFee}
                            columns={columns}
                            extraButtons={extraButtons}
                        />
                    </div>
                </div>
                </div>
            </div>
            </div>
            {/* <Container fluid className='common_bg position-relative content_manage'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Content Management'} />
                        <div className='setting_tab_holder mt-5 pe-2'>
                            <Row>
                                <Col sm={6} className='d-flex gap-3 mb-3 mb-sm-0'>

                                </Col>
                                <Col sm={6} className='text-end'>
                                    <NavLink to='/addsociallink' className='sidebar_links'>
                                        <button className='orange_small_primary'>Add Sociallink</button>
                                    </NavLink>
                                </Col>
                            </Row>
                        </div>

                        <div className='common_page_scroller mt-4'>
                            <div className='exchange_table_holder dashboard_box rounded-3'>
                                <ReactDatatable
                                    config={config}
                                    records={getFee}
                                    columns={columns}
                                    extraButtons={extraButtons}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container> */}
  
            {/* modals */}

            {showEdit && <EditSocaillink show={showEdit} handleClose={() => { handleCloseEdit() }} getSettings={() => { GetCmscontents() }} editData={editData} />}

        </>
    )
}

export default Sociallinklist