



import React, { Component, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
// import bsCustomFileInput from 'bs-custom-file-input';
import Navbar from "../../partials/Navbar";
import Sidebar from '../../partials/Sidebar.js';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import { CKEditor } from 'ckeditor4-react';
import { AddRoadmap } from '../../../actions/cmsAxios';
toast.configure();


export function Roadmapadd() {

    const history = useHistory();





    const initData = {
        "heading": "",
        "description": "",
        "step": 0

    }

    const [formData, setFormData] = useState({});
    const [ans, setAns] = useState('<p></p>')
    console.log("fffooomm", formData);

    // var form=formData



    const handlechange = async (e) => {

        e.preventDefault();


        const { id, value } = e.target;
        console.log("valuesss", id, value)


        setFormData({ ...formData, ...{ [id]: value } })

        console.log("formData", formData)
    }

    const onEditorChange = (e) => {
        var content_text = e.editor.getData()
        setAns(content_text)

        //   setFormData({...formData,...{'content' : content_text}})
        //   console.log("fff",formData,{...formData,...{'content' : content_text}})
    }

    const handleSubmit = async () => {


        let Data = {
            heading: formData.heading,
            description: ans,
            step: formData.step,


        }

        console.log("paylodasss", Data);
        var resp = await AddRoadmap(Data);
        console.log("erwerss", resp);
        if (resp?.status) {
            toast.success("User Added Succesfully")
            setTimeout(() => {
                history.push("/roadmaplist")
            }, 1000);

        }
        else return toast.error(resp.msg)


    }






    return (

        <>

            <Navbar />
            <div className="d-flex" id="wrapper">
            <Sidebar/>
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                    <div>
                        <button className='btn mt-2 allbtn mb-3 btn-primary' type='button' onClick={() => history.goBack()} >Back</button></div>
                        <h1 className="mt-2 text-primary">Add Roadmap</h1>

                        <form className="forms-sample">
                            <Form.Group>
                                <label htmlFor="exampleInputName1" className='text-white'>Step</label>
                                <Form.Control type="number" className="form-control" id="step" placeholder="Enter step" value={formData?.step} onChange={(e) => handlechange(e)} />
                            </Form.Group>
                            <Form.Group>
                                <label htmlFor="exampleInputName1" className='text-white'>Heading</label>
                                <Form.Control type="text" className="form-control" id="heading" placeholder="Enter heading" value={formData?.heading} onChange={(e) => handlechange(e)} />
                            </Form.Group>
                
                            <Form.Group>
                                <label htmlFor="exampleInputName1" className='text-white'>Description</label>
                                {/* <input type="text" id="heading" onChange={(e)=>handlechange(e)}/> */}
                                {
                                        
                                    <CKEditor
                                        initData={ans}
                                        onChange={(e) => onEditorChange(e)}
                                    />}
                            </Form.Group>

                        </form>
                        <div>
                            <button className='btn mt-3  allbtn btn-primary' onClick={() => handleSubmit()}>SUBMIT</button>
                        </div>
                    </div>
                </div>
            </div>

        <div>

            {/* <div className="row">

                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body"> */}
                            {/* <div>
                                <button className='btn mt-2 allbtn mb-3' type='button' onClick={() => history.goBack()} >Back</button></div>
                            <h4 className="card-title">Add Roadmap</h4> */}

                            {/* <form className="forms-sample"> */}
                                {/* <Form.Group>
                                    <label htmlFor="exampleInputName1">Step</label>
                                    <Form.Control type="number" className="form-control" id="step" placeholder="Enter step" value={formData?.step} onChange={(e) => handlechange(e)} />
                                </Form.Group>
                                <Form.Group>
                                    <label htmlFor="exampleInputName1">Heading</label>
                                    <Form.Control type="text" className="form-control" id="heading" placeholder="Enter heading" value={formData?.heading} onChange={(e) => handlechange(e)} />
                                </Form.Group> */}
                   
                                {/* <Form.Group>
                                    <label htmlFor="exampleInputName1">Description</label> */}
                                    {/* <input type="text" id="heading" onChange={(e)=>handlechange(e)}/> */}
                                    {/* {
                                         
                                        <CKEditor
                                            initData={ans}
                                            onChange={(e) => onEditorChange(e)}
                                        />} */}
                                {/* </Form.Group> */}

                            {/* </form> */}
                            {/* vk */}
                            {/* {(

                <img
                  src={
                    formData.file
                      ? typeof formData.file == "object"
                        ? window.URL.createObjectURL(
                          formData.file
                        )
                        : ""
                      : staticimg
                  }
                  width={100}
                />

              )}
              <br />
              <br />

              <input type="file" id="file" onChange={(e) => handlechange(e)} /> */}


                            {/* <div>
                                <button className='btn mt-3  allbtn' onClick={() => handleSubmit()}>SUBMIT</button>
                            </div> */}
{/* 
                        </div>
                    </div>
                </div>

            </div> */}
        </div>

        </>
    )

}

export default Roadmapadd;
