import React, { Component, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Button, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Navbar from "../../partials/Navbar";
import Sidebar from '../../partials/Sidebar.js';
import ReactDatatable from '@ashvin27/react-datatable';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { IMG_URL } from '../../config/env.js';
import { hideshowroadmap, roadmaplist } from '../../../actions/cmsAxios.js';

toast.configure();


export default function Roadmaplist(props) {
    console.log("hfjhdfsfss", IMG_URL)

    var history = useHistory();

    //   var location = useLocation();
    //   const{pathname,state}=location;
    //   const path = pathname.split("/")[1]
    //   console.log("pathname,stae",pathname,state,path)


    //   const Wallet_Details = useSelector((state)=>state.wallet_detail)
    const [article, setArticlelist] = useState([])
    console.log("article", article)


    const columns = [

        {
            key: "heading",
            text: "Heading",
            className: "Email",
            align: "center",
            sortable: true,
            cell: rec => rec?.heading



        },

        {
            key: "description",
            text: "Description",
            className: "walletaddress",
            align: "center",
            sortable: true,
            cell: rec => {
                return (
                    <div dangerouslySetInnerHTML={{ __html: rec?.description.length > 100 ? rec?.description.slice(0, 100) + "..." : rec?.description }} />
                )
            }


        },

        {
            key: "Edit",
            text: "edit",
            className: "walletaddress",
            align: "center",
            sortable: true,
            cell: rec => <div><Link to={{ pathname: '/roadmapedit', state: rec }}><button className='btn mt-2 allbtn btn-primary' >EDIT</button></Link></div>



        },
        {
            key: "Hide/Show",
            text: "Hide/Show",
            className: "NFT IDT",
            align: "left",

            cell: rec =>
                <div>{console.log("recereecat", rec)}<button className='btn mt-2 allbtn btn-primary' onClick={() => hideShowCategory(rec.hideshow == "visible" ? "hidden" : "visible", rec._id)}>{rec.hideshow == "hidden" ? "SHOW" : "HIDE"}</button></div>

        },

    ]

    useEffect(() => {

        getroadmaplist();

    }, [])



    const getroadmaplist = async () => {
        var resp = await roadmaplist();
        console.log("fffjjj", resp.data)
        if (resp?.status) {
            setArticlelist(resp.data)
        }
    }
    const hideShowCategory = async (data, _id) => {
        console.log("datasddtaat", data, _id);
        let payload = { hideshow: data, id: _id }
        let resp = await hideshowroadmap(payload);
        console.log("respresp", resp);
        if (resp?.status) {
            toast.success(resp.message)
            setTimeout(() => {
                getroadmaplist()
            }, 2000);
        }
        else toast.error(resp.data.data.message)

    }






    return (

        <>
            {/* {(path && path == "editsociallink")? */}
            {/* <EditSocial rec={state?state:{}}/>: */}

            <Navbar />
            <div className="d-flex" id="wrapper">
            <Sidebar/>
                {/* <div className="page-header">
                    <nav aria-label="breadcrumb">
                    </nav>
                </div> */}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h1 className="mt-2 text-primary">About Roadmap</h1>
                        <div className='d-flex align-items-center justify-content-between'>
                            <button className='btn allbtn btn-primary' type='button' onClick={() => history.push('/')} >Back</button>
                            {article.length > 5 ? "" : <Link to="/roadmapadd">
                            <button className='btn mt-2 allbtn mb-3 btn-primary'>Add Roadmap</button>
                            </Link>}
                        </div>
                        

                        <div className="responsive-table">
                            <ReactDatatable

                                records={article}
                                columns={columns}
                            />

                        </div>
                    </div>
                </div>
                {/* <div className="row">

                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">About Roadmap</h4>
                                <div>
                                    <button className='btn mt-2 allbtn mb-3' type='button' onClick={() => history.push('/')} >Back</button></div>
                                {article.length > 5 ? "" : <Link to="/roadmapadd">
                                    <button className='btn mt-2 allbtn mb-3'>Add Roadmap</button>
                                </Link>}

                                <div className="table-responsive">
                                    <ReactDatatable

                                        records={article}
                                        columns={columns}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                </div> */}
            </div>

            {/* } */}

        </>

    )

}
