import axios from "axios";
import backurl from "../lib/config";


export const Addblocklisthooks = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': backurl+"/api/add-blocklist",
            'data': data
        });
        return respData
    } catch (err) {
       
    }
}

export const Removeblocklisthooks = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': backurl+"/api/remove-blocklist",
            'data': data
        });
        return respData
    } catch (err) {
       
    }
}

