import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addArticles } from "../../actions/formActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import  Web3 from 'web3';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))


let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false, 
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class ArticlesAddModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
            Title: "",
            Heading:"",
            Description: "",
            NewsLink : "",
            Description1: "",
            NewsLink1 : "",
            Description2: "",
            NewsLink2: "",
            Description3: "",
            NewsLink3 : "",
            VimeoLink : "",
            VimeoLink1 : "",
            VimeoLink2 : "",
            VimeoLink3 : "",
            errors: {},
            file : {},
};
    }
   
    componentWillReceiveProps(nextProps) {
       
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }

        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined) {
            $('#add-articles-modal').modal('hide');
            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            
        }
    }
   
    onChange = e => {
        
        this.setState({ [e.target.id]: e.target.value });

    };
     
    onFileChange = event => {
        var name = event.target.value;
           // Update the state
           this.setState({ file: event.target.files[0]});
           this.setState({ imagepath: name});
         //console.log(name);
         };

         handleChange = (value) => {
            this.setState({ Description:value });       
           }
           handleChange1 = (value) => {
            this.setState({ Description1:value });       
           }
           handleChange2 = (value) => {
            this.setState({ Description2:value });       
           }
           handleChange3 = (value) => {
            this.setState({ Description3:value });       
           }

 
    onStakeAdd = e => {
        e.preventDefault();
        const newStake = {
            Title: this.state.Title,
            Heading:this.state.Title,
            Description: this.state.Description,
            NewsLink : this.state.NewsLink,
            VimeoLink : this.state.VimeoLink,
            Description1: this.state.Description1,
            NewsLink1 : this.state.NewsLink1,
            VimeoLink1 : this.state.VimeoLink1,
            Description2: this.state.Description2,
            NewsLink2:this.state.NewsLink2,
            VimeoLink2 : this.state.VimeoLink2,
            Description3: this.state.Description3,
            NewsLink3 : this.state.NewsLink3,
            VimeoLink3 : this.state.VimeoLink3,
            file: this.state.file
            
        };
       
        this.props.addArticles(newStake, this.props.history);
       // $('#add-pool-modal').modal('hide');
        
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-articles-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add News Articles</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate id="update-form">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="form-update-id"
                                        type="text"
                                        required
                                        className="d-none"/>
                                  
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenAddresses">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Title}
                                                error={errors.Title}
                                                id="Title"
                                                type="text"
                                                required
                                                className={classnames("form-control", {
                                                    invalid: errors.Title
                                                })}
                                            />
                                            <span className="text-danger">{errors.Title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">Heading</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.Heading}
                                                error={errors.Heading}
                                                id="Heading"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Description 1</label>
                                        </div>
                                        <div className="col-md-9">
                                        <ReactQuill                                               
                                        
                                               onChange={this.handleChange}
                                                value={this.state.Description}
                                                error={errors.Description}
                                                id="Description"
                                                theme="snow" 
                                                   type="text" 
                                                // className={classnames("form-control", {
                                                //     invalid: errors.Description
                                                // })}
                                            />
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Youtube Link 1</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                              
                                               onChange={this.onChange}
                                                value={this.state.NewsLink}
                                                error={errors.NewsLink}
                                                id="NewsLink"
                                               
                                                className={classnames("form-control", {
                                                    invalid: errors.NewsLink
                                                })}
                                            ></input>
                                            <span className="text-danger">{errors.NewsLink}</span>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Vimeo Link 1</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                              
                                               onChange={this.onChange}
                                                value={this.state.VimeoLink}
                                                error={errors.VimeoLink}
                                                id="VimeoLink"
                                               
                                                className={classnames("form-control", {
                                                    invalid: errors.VimeoLink
                                                })}
                                            ></input>
                                            <span className="text-danger">{errors.VimeoLink}</span>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Description 2</label>
                                        </div>
                                        <div className="col-md-9">
                                        <ReactQuill                                               
                                        
                                               onChange={this.handleChange1}
                                                value={this.state.Description1}
                                                error={errors.Description1}
                                                id="Description1"
                                                theme="snow" 
                                                   type="text" 
                                                // className={classnames("form-control", {
                                                //     invalid: errors.Description
                                                // })}
                                            />
                                            <span className="text-danger">{errors.Description1}</span>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Youtube Link2</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                              
                                               onChange={this.onChange}
                                                value={this.state.NewsLink1}
                                                error={errors.NewsLink1}
                                                id="NewsLink1"
                                               
                                                className={classnames("form-control", {
                                                    invalid: errors.NewsLink1
                                                })}
                                            ></input>
                                            <span className="text-danger">{errors.NewsLink1}</span>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Vimeo Link 2</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                              
                                               onChange={this.onChange}
                                                value={this.state.VimeoLink1}
                                                error={errors.VimeoLink1}
                                                id="VimeoLink1"
                                               
                                                className={classnames("form-control", {
                                                    invalid: errors.VimeoLink1
                                                })}
                                            ></input>
                                            <span className="text-danger">{errors.VimeoLink1}</span>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Description 3</label>
                                        </div>
                                        <div className="col-md-9">
                                        <ReactQuill                                               
                                        
                                               onChange={this.handleChange2}
                                                value={this.state.Description2}
                                                error={errors.Description2}
                                                id="Description2"
                                                theme="snow" 
                                                   type="text" 
                                                // className={classnames("form-control", {
                                                //     invalid: errors.Description
                                                // })}
                                            />
                                            <span className="text-danger">{errors.Description2}</span>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Youtube Link3</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                              
                                               onChange={this.onChange}
                                                value={this.state.NewsLink2}
                                                error={errors.NewsLink2}
                                                id="NewsLink2"
                                               
                                                className={classnames("form-control", {
                                                    invalid: errors.NewsLink2
                                                })}
                                            ></input>
                                            <span className="text-danger">{errors.NewsLink2}</span>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Vimeo Link 3</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                              
                                               onChange={this.onChange}
                                                value={this.state.VimeoLink2}
                                                error={errors.VimeoLink2}
                                                id="VimeoLink2"
                                               
                                                className={classnames("form-control", {
                                                    invalid: errors.VimeoLink2
                                                })}
                                            ></input>
                                            <span className="text-danger">{errors.VimeoLink2}</span>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Description 4</label>
                                        </div>
                                        <div className="col-md-9">
                                        <ReactQuill                                               
                                        
                                               onChange={this.handleChange3}
                                                value={this.state.Description3}
                                                error={errors.Description3}
                                                id="Description3"
                                                theme="snow" 
                                                   type="text" 
                                                // className={classnames("form-control", {
                                                //     invalid: errors.Description
                                                // })}
                                            />
                                            <span className="text-danger">{errors.Description3}</span>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Youtube Link4</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                              
                                               onChange={this.onChange}
                                                value={this.state.NewsLink3}
                                                error={errors.NewsLink3}
                                                id="NewsLink3"
                                               
                                                className={classnames("form-control", {
                                                    invalid: errors.NewsLink3
                                                })}
                                            ></input>
                                            <span className="text-danger">{errors.NewsLink3}</span>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Vimeo Link 4</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                              
                                               onChange={this.onChange}
                                                value={this.state.VimeoLink3}
                                                error={errors.VimeoLink3}
                                                id="VimeoLink3"
                                               
                                                className={classnames("form-control", {
                                                    invalid: errors.VimeoLink3
                                                })}
                                            ></input>
                                            <span className="text-danger">{errors.VimeoLink3}</span>
                                        </div>
                                       
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="logoURI">Update Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFileChange}
                                                value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <a
                                    form="add-stake"
                                  
                                    className="btn btn-primary" onClick={this.onStakeAdd}>
                                    Add
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ArticlesAddModal.propTypes = {
    addStake: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addArticles }
)(withRouter(ArticlesAddModal));
