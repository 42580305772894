import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import backurl from "../../lib/config";
import axios from "axios";
import BigNumber from "bignumber.js";

import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { Form, Modal, Button, InputGroup, FormControl } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import "react-toastify/dist/ReactToastify.css";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";

// import wall1 from "";
// import wall2 from "";

import { CONTRACT_ABI, CONTRACT_ADDRESS } from "../config/presale";
import { ERC20_ABI, ERC20_ADDRESS } from "../config/token";

import { toast, ToastContainer } from "react-toastify";
// import 'bootstrap/dist/css/bootstrap.css'
// import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

let toasterOption1 = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
class ManageSale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 20,
      show: false,
      user: "",
      token: "",
      presale: "",
      amount: 0,
      balance: 0,
      collected: 0,
      price: 0,
      Busdprice: 0,
      min: 0,
      max: 0,
      BUSDmin: 0,
      BUSDmax: 0,
      web3state: "",
      days: 0,
      salesAdminDetails: [{}],
      people: 1,
      inputList: [{ user: "", bnbvalue: "" }],
      isWhitelisted: false,
      tokenAddress: "",
      tokenDecimal: 0,
      hardcap: 0,
      BUSDhardcap: 0,
      iscrpto: "",
      stakerAddress: "",
      staker: "",
      isPresaleOpen: true,
      currentpoolid: 0,
      BNBearnedCap: 0,
      BUSDearnedCap: 0,
      DepositToken: 0,
    };
  }

  componentDidMount() {
    this.setState({ show: false });
    // this.MetaMask();
    if (localStorage.getItem("walletconnect") != null) {
      this.enableWallet();
    } else {
      this.MetaMask();
    }
  }

  editRecord(record) {
    this.setState({ currentRecord: record });
  }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  getAdminDetails = async () => {
    //console.log(window.location.pathname.split("/")[2], "path");
    const PresaleAddress = window.location.pathname.split("/")[2];
    const carddata = {
      cardPresaleAddress: PresaleAddress,
    };
    await axios
      .post(backurl + "/api/getCardDetails", carddata)
      .then((result) => {
        this.setState({ salesAdminDetails: result.data[0] });
        console.log("o321f321sdf312sd32f1k", result);
        // console.log("====unlock days calculated====",new Date(this.state.salesAdminDetails.SaleEndsIn).getTime(),"unlockon",this.state.unlockon);
      })
      .catch((e) => {
        //console.log("eo32nuihnjui1f321sdf312sd32f1k", e)
      });
  };
  MetaMask = async (e) => {
    await this.getAdminDetails();
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      this.setState({ web3state: web3 });
      try {
        window.ethereum.enable().then(async () => {
          // User has allowed account access to DApp...
          const accounts = await web3.eth.getAccounts();
          // debugger
          console.log("Account : ", accounts[0]);
          const data = accounts[0];

          this.setState({ user: data });
          this.setState({ show: false });
          const Presale = new web3.eth.Contract(
            CONTRACT_ABI,
            this.state.salesAdminDetails.PresaleAddress
          );
          this.setState({ presale: Presale });
          console.log("Presale", Presale);
          const TokenAdd = await Presale.methods.tokenAddress().call();
          this.setState({ tokenAddress: TokenAdd });
          const stakeraddress = await this.state.presale.methods
            .Staker()
            .call();
          this.setState({ stakerAddress: stakeraddress });
          console.log("TokenAdd", TokenAdd, stakeraddress);
          const Token = new web3.eth.Contract(
            ERC20_ABI,
            this.state.salesAdminDetails.TokenAddress.toString()
          );
          this.setState({ token: Token });
          this.getData();
          // toast.success('Wallet Connected !', toasterOption);
        });
      } catch (e) {
        // User has denied account access to DApp...
      }
    }
    // Legacy DApp Browsers
    else if (window.web3) {
      const web3 = new Web3(window.web3.currentProvider);
      this.setState({ web3state: web3 });
      const accounts = await web3.eth.getAccounts();
      console.log("Account : ", accounts[0]);
      const data = accounts[0];
      this.setState({ user: data });
      this.setState({ show: false });
      const Presale = new web3.eth.Contract(
        CONTRACT_ABI,
        this.state.salesAdminDetails.PresaleAddress
      );
      this.setState({ presale: Presale });
      const TokenAdd = await Presale.methods.tokenAddress().call();
      this.setState({ tokenAddress: TokenAdd });
      const stakeraddress = await this.state.presale.methods.Staker().call();
      this.setState({ stakerAddress: stakeraddress });
      console.log("Tokenaddress", TokenAdd);
      const Token = new web3.eth.Contract(
        ERC20_ABI,
        this.state.salesAdminDetails.TokenAddress.toString()
      );
      this.setState({ token: Token });
      this.getData();
    }
    // Non-DApp Browsers
    else {
      console.log("No Dapp Supported Wallet Found");
    }
  };

  enableWallet = async (e) => {
    //Create WalletConnect Provider
    console.log("Wallet connect");
    // const provider = new WalletConnectProvider({
    //     rpc: {
    //         56: "https://bsc-dataseed.binance.org",
    //         // 56:"https://data-seed-prebsc-2-s1.binance.org:8545/"
    //         // ...
    //     },
    // }
    // );
    const provider = new WalletConnectProvider({
      rpc: {
        //  56: "https://bsc-dataseed1.ninicoin.io",
        97: "https://data-seed-prebsc-1-s1.binance.org:8545",
        // ...
      },
      network: "binance",
      chainId: 97,
      // infuraId: "69de03b5c7194095980c9233f9cf71df",
    });
    await provider
      .enable()
      .then(function (error, result) {
        console.log("error: " + error);
        console.log(result);
      })
      .catch((e) => {
        //try again
      });
    await this.getAdminDetails();
    const web3 = new Web3(provider);
    this.setState({ web3state: web3 });
    const accounts = await web3.eth.getAccounts();

    const data = accounts[0];
    console.log("Account : ", accounts[0]);

    this.setState({ user: data });
    this.setState({ show: false });
    const Presale = new web3.eth.Contract(
      CONTRACT_ABI,
      this.state.salesAdminDetails.PresaleAddress
    );
    this.setState({ presale: Presale });
    console.log("Presale", Presale);
    const TokenAdd = await Presale.methods.tokenAddress().call();
    this.setState({ tokenAddress: TokenAdd });
    const stakeraddress = await this.state.presale.methods.Staker().call();
    this.setState({ stakerAddress: stakeraddress });
    console.log("TokenAdd", TokenAdd);
    const Token = new web3.eth.Contract(
      ERC20_ABI,
      this.state.salesAdminDetails.TokenAddress.toString()
    );
    this.setState({ token: Token });
    // this.getData()

    this.getData();
  };

  getData = async () => {
    // this.setState({ desc: axiosdesc })
    const decimal = await this.state.token.methods.decimals().call();
    this.setState({ tokenDecimal: decimal });
    const balance =
      (await this.state.token.methods
        .balanceOf(this.state.salesAdminDetails.PresaleAddress)
        .call()) /
      10 ** parseFloat(decimal);
    // const roundvalue = this.UseRoundValue(balance,this.state.tokenDecimal)
    // this.setState({ balance: Math.round(balance) });
    this.setState({ balance: balance });
    const BNBbalance = await this.state.web3state.eth.getBalance(
      this.state.salesAdminDetails.PresaleAddress
    );
    this.setState({ collected: BNBbalance / 10 ** 18 });
    const BNBprice = await this.state.presale.methods.tokenRatePerEth().call();
    this.setState({ price: BNBprice });
    const crypto = await this.state.presale.methods._crypto().call();
    console.log("this.state.presale.methods", crypto);
    const BUSD_price = await this.state.presale.methods
      .tokenRatePercrypto()
      .call();
    this.setState({ Busdprice: BUSD_price });
    const bnbearnedCap =
      (await this.state.presale.methods.earnedCap().call()) / 10 ** 18;
    this.setState({ BNBearnedCap: bnbearnedCap });
    const busdearnedCap =
      (await this.state.presale.methods.BUSDearnedCap().call()) / 10 ** 18;
    console.log("BUSDearnedCap", busdearnedCap);
    this.setState({ BUSDearnedCap: busdearnedCap });

    // this.setState({ BUSDearnedCap: Math.round(busdearnedCap) });

    const whitelist = await this.state.presale.methods.isWhitelisted().call();
    this.setState({ isWhitelisted: whitelist });
    const min =
      (await this.state.presale.methods.minEthLimit().call()) / 10 ** 18;
    this.setState({ min: min });
    console.log(min);
    const max =
      (await this.state.presale.methods.maxEthLimit().call()) / 10 ** 18;
    this.setState({ max: max });
    const busdmin =
      (await this.state.presale.methods.BUSDminEthLimit().call()) / 10 ** 18;
    this.setState({ BUSDmin: busdmin });
    console.log(min);
    const busdmax =
      (await this.state.presale.methods.BUSDmaxEthLimit().call()) / 10 ** 18;
    this.setState({ BUSDmax: busdmax });
    const hardCap =
      (await this.state.presale.methods.hardCap().call()) / 10 ** 18;
    this.setState({ hardcap: hardCap });

    const busdhardCap =
      (await this.state.presale.methods.BUSDhardCap().call()) / 10 ** 18;
    // this.setState({ BUSDhardcap: Math.round(busdhardCap) });
    this.setState({ BUSDhardcap: busdhardCap });

    console.log("busdhardCap", busdhardCap);
    // const depositToken = (this.state.price * this.state.hardcap) +  (this.state.Busdprice *  this.state.BUSDhardcap)
    const depositToken = this.state.Busdprice * this.state.BUSDhardcap;

    this.setState({ DepositToken: depositToken });

    const days = await this.state.presale.methods.intervalDays().call();
    this.setState({ days: days / 60 / 60 / 24 });
    const PresaleOwner = await this.state.presale.methods.owner().call();
    const tokenOwner = await this.state.token.methods.owner().call();
    const iscrpto = await this.state.presale.methods.iscrypto().call();
    const isclaium = await this.state.presale.methods.isClaimable().call();
    const ispresalepopen = await this.state.presale.methods
      .isPresaleOpen()
      .call();
    this.setState({ isPresaleOpen: ispresalepopen });
    console.log("isPresaleOpen>>", ispresalepopen);
    const currentpool = await this.state.presale.methods.currentPoolId().call();
    this.setState({ currentpoolid: currentpool });
    console.log("currentpoolid>>", currentpool);
    if (PresaleOwner != this.state.user && tokenOwner != this.state.user) {
      console.log("host :", window.location.origin);
      // window.location.href=window.location.origin +"/buy-token";
    }
  };

  UseRoundValue = (VALUE, DECIMALS) => {
    return VALUE > 0
      ? Math.round(
          new BigNumber(VALUE).div(new BigNumber(10).pow(DECIMALS)).toNumber()
        )
      : new BigNumber(0);
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.inputList];
    list[index][name] = value;
    console.log(list, "sdf");
  };

  handleRemoveClick = (index) => {
    console.log("index", index);
    const list = [...this.state.inputList];
    list.splice(index, 1);
    this.setState({ inputList: list });
  };

  // handle click event of the Add button
  handleAddClick = () => {
    this.setState({
      inputList: [...this.state.inputList, { user: "", bnbvalue: "" }],
    });
  };

  handleSumbit = () => {
    console.log(this.state.inputList, "------------------fiunal");

    var list = this.state.inputList;
    console.log("listleng", list.length);
    var userList = [];
    var bnbvalues = [];
    Object.keys(list).forEach(function (key) {
      userList.push(list[key].user);
      const amount = (list[key].bnbvalue * 10 ** 18).toString();
      bnbvalues.push(amount);
    });
    console.log("..............userList", userList);

    var whitelistedAddresses = this.state.presale.methods
      .addMultipleWhitelistedAddresses(userList, bnbvalues)
      .send({ from: this.state.user })
      .then(async (result) => {
        this.setState({ show1: false });
      })
      .catch((e) => {
        //try again
        toast.error("Try Again !", toasterOption);
      });
  };

  sliderClick = async () => {
    console.log("...........changed...........");
    const isWhitelisteds = await this.state.presale.methods
      .whitelistedSale()
      .send({ from: this.state.user })
      .then(async (result) => {
        this.getData();
      })
      .catch((e) => {
        //try again
        toast.error("Try Again !", toasterOption);
      });
  };

  toFixed(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    return x;
  }
  Deposit = async (value) => {
    // value = this.toFixed(value * (10 ** this.state.tokenDecimal));
    const web3 = new Web3(window.ethereum);
    // var depositvalue = web3.utils.toWei(String(value))
    var depositvalue = this.toFixed(value * 10 ** this.state.tokenDecimal);

    // const roundvalue = this.UseRoundValue(value,this.state.tokenDecimal)

    console.log(
      "depositvalue",
      this.state.user,
      this.state.salesAdminDetails.PresaleAddress
    );

    await this.state.token.methods
      .transfer(
        this.state.salesAdminDetails.PresaleAddress,
        depositvalue.toString()
      )
      .send({ from: this.state.user })
      .then(async (result) => {
        console.log(result);
        const balance = await this.state.token.methods
          .balanceOf(this.state.salesAdminDetails.PresaleAddress)
          .call();
        this.setState({ balance: balance / 10 ** this.state.tokenDecimal });
        console.log(
          "balance / 10 ** this.state.tokenDecimal",
          balance / 10 ** this.state.tokenDecimal
        );
        window.location.reload();
      })
      .catch((e) => {
        //try again
        toast.error("Try Again !", toasterOption);
      });
  };

  setHardcap = async () => {
    // const hardCapvalue = this.toFixed(this.state.hardcap * 10 **18);
    const web3 = new Web3(window.ethereum);
    var hardCapvalue = web3.utils.toWei(String(this.state.hardcap));
    await this.state.presale.methods
      .setHardCap(hardCapvalue.toString())
      .send({ from: this.state.user });
    window.location.reload();
  };
  setBUSDHardcap = async () => {
    const web3 = new Web3(window.ethereum);
    var busdhardCapvalue = web3.utils.toWei(String(this.state.BUSDhardcap));

    // const busdhardCapvalue = this.toFixed(this.state.BUSDhardcap * 10 **18);

    await this.state.presale.methods
      .setBUSDHardCap(busdhardCapvalue.toString())
      .send({ from: this.state.user });
    window.location.reload();
  };
  BNBMax = async (value) => {
    value = this.toFixed(value * 10 ** 18);
    await this.state.presale.methods
      .setMaxEthLimit(value.toString())
      .send({ from: this.state.user })
      .then(async (result) => {
        const max =
          (await this.state.presale.methods.maxEthLimit().call()) / 10 ** 18;
        this.setState({ max: max });
        toast.success("Transaction succeeded...", toasterOption);
      })
      .catch((e) => {
        toast.error("Try Again !", toasterOption);
      });
  };

  BNBMin = async (value) => {
    value = this.toFixed(value * 10 ** 18);

    await this.state.presale.methods
      .setMinEthLimit(value.toString())
      .send({ from: this.state.user })
      .then(async (result) => {
        const min =
          (await this.state.presale.methods.minEthLimit().call()) / 10 ** 18;
        this.setState({ min: min });
        toast.success("Transaction succeeded...", toasterOption);
      })
      .catch((e) => {
        toast.error("Try Again !", toasterOption);
      });
  };

  BUSDMax = async (value) => {
    value = this.toFixed(value * 10 ** 18);
    await this.state.presale.methods
      .setBUSDMaxEthLimit(value.toString())
      .send({ from: this.state.user })
      .then(async (result) => {
        const busdmax =
          (await this.state.presale.methods.BUSDmaxEthLimit().call()) /
          10 ** 18;
        this.setState({ BUSDmax: busdmax });
        toast.success("Transaction succeeded...", toasterOption);
      })
      .catch((e) => {
        toast.error("Try Again !", toasterOption);
      });
  };

  BUSDMin = async (value) => {
    value = this.toFixed(value * 10 ** 18);

    await this.state.presale.methods
      .setBUSDMinEthLimit(value.toString())
      .send({ from: this.state.user })
      .then(async (result) => {
        const busdmin =
          (await this.state.presale.methods.BUSDminEthLimit().call()) /
          10 ** 18;
        this.setState({ BUSDmin: busdmin });
        toast.success("Transaction succeeded...", toasterOption);
      })
      .catch((e) => {
        toast.error("Try Again !", toasterOption);
      });
  };
  SetBNBPrice = async (value) => {
    value = this.toFixed(value);
    console.log("value :", value);
    await this.state.presale.methods
      .setTokenRatePerEth(value.toString())
      .send({ from: this.state.user })
      .then(async (result) => {
        const price = await this.state.presale.methods.tokenRatePerEth().call();
        this.setState({ price: price });
        toast.success("Transaction succeeded...", toasterOption);
        await sleep(1000);
        window.location.reload();
      })
      .catch((e) => {
        toast.error("Try Again !", toasterOption);
      });
  };

  SetBUSDPrice = async (value) => {
    value = this.toFixed(value);
    console.log("value :", value);
    await this.state.presale.methods
      .setTokenRatePercrypto(value.toString())
      .send({ from: this.state.user })
      .then(async (result) => {
        const Busdprice = await this.state.presale.methods
          .tokenRatePercrypto()
          .call();
        this.setState({ Busdprice: Busdprice });
        toast.success("Transaction succeeded...", toasterOption);
        await sleep(1000);
        window.location.reload();
      })
      .catch((e) => {
        toast.error("Try Again !", toasterOption);
      });
  };
  startSale = async (value) => {
    if (this.state.balance != 0) {
      await this.state.presale.methods
        .startPresale(value)
        .send({ from: this.state.user })
        .then(async (result) => {
          console.log(result);
          const balance = await this.state.token.methods
            .balanceOf(this.state.salesAdminDetails.PresaleAddress)
            .call();
          this.setState({ balance: balance / 10 ** this.state.tokenDecimal });
          toast.success("Sale Started Successfully", toasterOption);
        })
        .catch((e) => {
          toast.error("Try Again !", toasterOption);
        });
    } else {
      toast.warning("Deposit tokens first!!!", toasterOption);
    }
  };
  stopSale = async () => {
    await this.state.presale.methods
      .closePresale()
      .send({ from: this.state.user })
      .then(async (result) => {
        console.log(result);
        const balance = await this.state.token.methods
          .balanceOf(this.state.salesAdminDetails.PresaleAddress)
          .call();
        this.setState({ balance: balance / 10 ** this.state.tokenDecimal });
        toast.success("Sale Stoped Successfully", toasterOption);
      })
      .catch((e) => {
        toast.error("Try Again !", toasterOption);
        console.log("err", e);
      });
  };

  WithdrawBNB = async () => {
    await this.state.presale.methods
      .withdrawBNB()
      .send({ from: this.state.user })
      .then(async (result) => {
        this.getData();
        toast.success("Transaction succeeded... ", toasterOption);
      })
      .catch((e) => {
        toast.error("Try Again !", toasterOption);
      });
  };

  getUnsoldTokens = async () => {
    await this.state.presale.methods
      .getUnsoldTokens()
      .send({ from: this.state.user })
      .then(async (result) => {
        this.getData();
      })
      .catch((e) => {
        toast.error("Try Again !", toasterOption);
      });
  };

  setToken = async (value) => {
    await this.state.presale.methods
      .setTokenAddress(value.toString())
      .send({ from: this.state.user })
      .then(async (result) => {
        this.MetaMask();
        this.getData();
        toast.success("Transaction succeeded...", toasterOption);
      })
      .catch((e) => {
        toast.error("Try Again !", toasterOption);
      });
  };

  disablewhitelist = async () => {
    await this.state.presale.methods
      .whitelistedSale()
      .send({ from: this.state.user })
      .then(() => {
        this.state.isWhitelisted
          ? toast.warning("whitelist option disabled!!!!", toasterOption)
          : toast.info("whitelist option enabled", toasterOption);

        window.location.reload();
      });
  };

  finaliseSale = async (value) => {
    if (this.state.isPresaleOpen === false) {
      await this.state.presale.methods
        .finalizeSale(value.toString())
        .send({ from: this.state.user })
        .then(async (result) => {
          console.log(result);
          const balance = await this.state.token.methods
            .balanceOf(this.state.salesAdminDetails.PresaleAddress)
            .call();
          this.setState({ balance: balance / 10 ** this.state.tokenDecimal });
          this.MetaMask();
          this.getData();
          toast.success("Transaction Succeeded...", toasterOption);
        })
        .catch((e) => {
          toast.error("Try Again !", toasterOption);
        });
    } else {
      toast.warning("Ensure that sale is stop or Not", toasterOption);
    }
  };

  Disconnect() {
    this.setState({ user: "" });
    toast.info("Wallet Disconnected !", toasterOption);
  }

  render() {
    const { value, show, show1 } = this.state;
    const copyText = (a, b) => {
      toast.success("Presale Address Copied", toasterOption);
    };

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />

          <div className="wallet-modal">
            {/* walet lists */}
            <Modal className="wallet-modal " show={show}>
              <Modal.Header className="pb-0">
                <div>
                  <h3 className="sec-head ">Select a Wallet</h3>
                  <p>Select your preferred wallet for payment</p>
                </div>
                <button
                  type="button"
                  class="close"
                  onClick={() => this.setState({ show: false })}>
                  <span aria-hidden="true">×</span>
                  <span class="sr-only">Close</span>
                </button>
              </Modal.Header>
              <Modal.Body className="select-wallet text-center pt-0">
                <div className="wallet-lists">
                  <ul>
                    <li onClick={this.MetaMask}>
                      <div className="wal-option">
                        <h3 className="side-head mb-0"> Metamask</h3>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li onClick={this.enableWallet}>
                      <div className="wal-option">
                        <h3 className="side-head mb-0">Wallet Connect</h3>
                      </div>
                    </li>
                  </ul>
                </div>
              </Modal.Body>
            </Modal>
          </div>

          <div id="page-content-wrapper" className="px-5 pt-4 pb-5">
            <div className="whitelist-modal">
              {/* whitelist lists */}
              <Modal className="wallet-modal" show={show1}>
                <Modal.Header className="pb-0">
                  <div>
                    <h3 className="sec-head ">Add Whitelist</h3>
                    <p>Add whitelist people</p>
                  </div>
                  <button
                    type="button"
                    class="close"
                    onClick={() => this.setState({ show1: false })}>
                    <span aria-hidden="true">×</span>
                    <span class="sr-only">Close</span>
                  </button>
                </Modal.Header>
                <Modal.Body className="select-wallet pt-3">
                  {this.state.inputList.map((x, i) => {
                    return (
                      <div>
                        {/* <p>{x.user}</p> */}
                        <div className="row form_row row_add_remove">
                          <div className="col-12 col-sm-5 px-1">
                            <div className="inputs">
                              <InputGroup className="">
                                <FormControl
                                  name="user"
                                  placeholder="User"
                                  onChange={(e) => this.handleInputChange(e, i)}
                                />
                              </InputGroup>
                            </div>
                          </div>
                          <div className="col-12 col-sm-5  px-1">
                            <div className="inputs">
                              <InputGroup className="">
                                <FormControl
                                  name="bnbvalue"
                                  placeholder="BNB Value"
                                  onChange={(e) => this.handleInputChange(e, i)}
                                />
                              </InputGroup>
                            </div>
                          </div>

                          {this.state.inputList.length !== 1 && (
                            <div className="col-6 col-sm-2  px-1">
                              <button
                                className="btn-block btn btn-theme btn_red cont-btn btn_sm_closw mb-2 mt-0"
                                onClick={() => this.handleRemoveClick(i)}>
                                x
                              </button>
                            </div>
                          )}

                          {this.state.inputList.length - 1 === i && (
                            <div className="col-6 col-sm-2  px-1">
                              <button
                                className="btn-block btn btn-block btn btn-withdraw btn-theme cont-btn btn_sm_closw mb-2 mt-0"
                                onClick={this.handleAddClick}>
                                +
                              </button>
                            </div>
                          )}

                          {/* <div className="col-6 col-md-2">
                  </div>
                  <div className="col-6 col-md-2">
                  </div> */}
                        </div>
                      </div>
                    );
                  })}
                  <div className="text-center">
                    <button
                      className="btn btn-purple btn-theme mt-3 cont-btn mb-2 btn_sumit_add"
                      onClick={this.handleSumbit}>
                      Submit
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            {/* whitelist */}
            <div className="hut-body">
              <div className="connect-wallet">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <p className="label_big_1 text-white">Wallet Address: </p>
                    <a
                      href="#"
                      className="btn  btn-theme mb-0  mt-0 cont-btn"
                      onClick={() =>
                        this.state.user === ""
                          ? this.setState({ show: true })
                          : this.Disconnect()
                      }>
                      {this.state.user === ""
                        ? "Connect wallet"
                        : this.state.user}
                    </a>
                  </div>

                  <div className="col-12 col-lg-6 right_sec_align">
                    <div className="presale-Address">
                      <p className="label_big_1 text-white">
                        Presale Address:{" "}
                      </p>

                      <CopyToClipboard
                        text={`${this.state.salesAdminDetails.PresaleAddress}`}
                        onCopy={() =>
                          copyText(
                            "invite link",
                            `${this.state.salesAdminDetails.PresaleAddress}`
                          )
                        }>
                        <a
                          href="#"
                          className="btn btn-theme mb-0  mt-0 cont-btn"
                          onClick={() =>
                            this.state.salesAdminDetails.PresaleAddress
                          }>
                          {this.state.salesAdminDetails.PresaleAddress === ""
                            ? "Presale Address"
                            : this.state.salesAdminDetails.PresaleAddress}
                        </a>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hut-cont">
                <div className="row mt-4">
                  <div className="col-12 col-sm-8 col-lg-6 mx-auto">
                    <div className="card card_yellow">
                      <div className="card-body p-0">
                        <ul className="buytokenadmin mb-0">
                          <li>
                            <div className="lists">
                              <p className="label_big_1">
                                Presale Token balance:{" "}
                              </p>
                              <p className="label_big_1">
                                {this.state.balance} TOKEN
                              </p>
                            </div>
                          </li>
                          {/* <li>
                                                        <div className="lists">
                                                            <p className="label_big_1">Collected BNB: </p>
                                                            <p className="label_big_1">{this.state.BNBearnedCap} BNB</p>
                                                        </div>
                                                    </li> */}

                          <li>
                            <div className="lists">
                              <p className="label_big_1">Collected USDT: </p>
                              <p className="label_big_1">
                                {this.state.BUSDearnedCap} USDT
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="saledet">
                  <div className="detail-list mt-4">
                    <div className="row form_row">
                      {/* <div className="col-12 col-md-6">
                                                <label>Price of Token in BNB</label>
                                                <div className="inputs">
                                                    <InputGroup className="">
                                                        <FormControl id="price" value={this.state.price} onChange={(e) => { this.setState({ price: e.target.value }) }}
                                                            placeholder=""
                                                            aria-describedby="basic-addon2"
                                                        />
                                                        <InputGroup.Append>
                                                            <Button  variant="outline-secondary" onClick={() => this.SetBNBPrice(this.state.price)} className="btn-theme">Update</Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </div>
                                            </div> */}
                      <div className="col-12 col-md-6">
                        <label>Price of Token in USDT</label>
                        <div className="inputs">
                          <InputGroup className="">
                            <FormControl
                              id="price"
                              value={this.state.Busdprice}
                              onChange={(e) => {
                                this.setState({ Busdprice: e.target.value });
                              }}
                              placeholder=""
                              aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append className="mx-3">
                              <Button
                                variant="outline-secondary"
                                onClick={() =>
                                  this.SetBUSDPrice(this.state.Busdprice)
                                }
                                className="btn-theme">
                                Update
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Token Address </label>
                        <div className="inputs">
                          <InputGroup className="">
                            <FormControl
                              id="price"
                              value={this.state.tokenAddress}
                              onChange={(e) => {
                                this.setState({ tokenAddress: e.target.value });
                              }}
                              placeholder=""
                              aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append className="mx-3">
                              <Button
                                variant="outline-secondary"
                                onClick={() =>
                                  this.setToken(this.state.tokenAddress)
                                }
                                className="btn-theme">
                                Update
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      </div>
                      {/* <div className="col-12 col-md-6">
                                                <label>Minimum Contribution For BNB</label>
                                                <div className="inputs">
                                                    <InputGroup className="">
                                                        <FormControl id="min_contribution" value={this.state.min} onChange={(e) => { this.setState({ min: e.target.value }) }}
                                                            placeholder=""
                                                            aria-describedby="basic-addon2"
                                                        />
                                                        <InputGroup.Append>
                                                            <Button variant="outline-secondary" onClick={() => this.BNBMin(this.state.min)} className="btn-theme">Update</Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </div>
                                            </div> */}
                      {/* <div className="col-12 col-md-6">
                                                <label>Maximum Contribution For BNB</label>
                                                <div className="inputs">
                                                    <InputGroup className="">
                                                        <FormControl id="max_contribution" value={this.state.max} onChange={(e) => { this.setState({ max: e.target.value }) }}
                                                            placeholder=""
                                                            aria-describedby="basic-addon2"
                                                        />
                                                        <InputGroup.Append>
                                                            <Button variant="outline-secondary" onClick={() => this.BNBMax(this.state.max)} className="btn-theme">Update</Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </div>
                                            </div> */}
                      {/* <div className="col-12 col-md-6">
                                                <label>Hard Cap For BNB</label>
                                                <div className="inputs">
                                                    <InputGroup className="">
                                                        <FormControl id="amt" value={this.state.hardcap} onChange={(e) => { this.setState({ hardcap: e.target.value }) }}
                                                            placeholder=""
                                                            aria-describedby="basic-addon2"
                                                        />
                                                        <InputGroup.Append>
                                                            <Button variant="outline-secondary" onClick={() => this.setHardcap(this.state.hardcap)} className="btn-theme">Update</Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </div>
                                            </div> */}
                      <div className="col-12 col-md-6">
                        <label>Minimum Contribution For USDT</label>
                        <div className="inputs">
                          <InputGroup className="">
                            <FormControl
                              id="busdmin_contribution"
                              value={this.state.BUSDmin}
                              onChange={(e) => {
                                this.setState({ BUSDmin: e.target.value });
                              }}
                              placeholder=""
                              aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append className="mx-3">
                              <Button
                                variant="outline-secondary"
                                onClick={() => this.BUSDMin(this.state.BUSDmin)}
                                className="btn-theme">
                                Update
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Maximum Contribution For USDT</label>
                        <div className="inputs">
                          <InputGroup className="">
                            <FormControl
                              id="busdmax_contribution"
                              value={this.state.BUSDmax}
                              onChange={(e) => {
                                this.setState({ BUSDmax: e.target.value });
                              }}
                              placeholder=""
                              aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append className="mx-3">
                              <Button
                                variant="outline-secondary"
                                onClick={() => this.BUSDMax(this.state.BUSDmax)}
                                className="btn-theme">
                                Update
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Hard Cap For USDT</label>
                        <div className="inputs">
                          <InputGroup className="">
                            <FormControl
                              id="BUSDamt"
                              value={this.state.BUSDhardcap}
                              onChange={(e) => {
                                this.setState({ BUSDhardcap: e.target.value });
                              }}
                              placeholder=""
                              aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append className="mx-3">
                              <Button
                                variant="outline-secondary"
                                onClick={() =>
                                  this.setBUSDHardcap(this.state.BUSDhardcap)
                                }
                                className="btn-theme">
                                Update
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <label>Deposit token</label>
                        <div className="inputs">
                          <InputGroup className="">
                            <FormControl
                              id="amt"
                              value={this.state.DepositToken}
                              onChange={(e) => {
                                this.setState({ DepositToken: e.target.value });
                              }}
                              readOnly={true}
                              placeholder=""
                              aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append className="mx-3">
                              <Button
                                variant="outline-secondary"
                                onClick={() =>
                                  this.Deposit(this.state.DepositToken)
                                }
                                className="btn-theme ">
                                Deposit
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Number of days for sale</label>

                        <div className="inputs">
                          <InputGroup className="">
                            <FormControl
                              id="days"
                              value={this.state.days}
                              onChange={(e) => {
                                this.setState({ days: e.target.value });
                              }}
                              placeholder=""
                              aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append className="mx-3">
                              <Button
                                variant="outline-secondary"
                                onClick={() => this.startSale(this.state.days)}
                                className="btn-theme ">
                                Start Sale
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mt-4 mb-3">
                        <button
                          onClick={() => this.stopSale()}
                          className="btn-block btn btn-green btn-theme btn btn-primary cont-btn ">
                          Stop sale
                        </button>
                      </div>

                      <div className="col-12 col-xl-12 mt-4">
                        <div className="btns text-center">
                          <div className="row mt-3">
                            {/* <div className="col-12 col-sm-6 col-md-6">
                                                            <button onClick={() => this.getUnsoldTokens()} className="btn-block btn btn-green btn-theme btn btn-primary cont-btn ">Get Unsold</button>

                                                        </div> */}
                            {/* <div className="col-12 col-sm-6 col-md-3  px-1">
                                                            <button onClick={() => this.WithdrawBNB()} className="btn-block btn btn-green btn-theme btn btn-primary cont-btn mt-3">Withdraw BNB</button>


                                                        </div> */}
                            {/* <div className="col-12 col-sm-6 col-md-3  px-1">
                                                            <button onClick={() => this.finaliseSale()} className="btn-block btn btn-green btn-theme btn btn-primary cont-btn mt-3">Finalize sale</button>


                                                        </div> */}
                            <div className="col-12 col-md-6">
                              {/* <div className="inputs">
                                <InputGroup className="">
                                  <FormControl
                                    id="stakerAddress"
                                    value={this.state.stakerAddress}
                                    onChange={(e) => {
                                      this.setState({
                                        stakerAddress: e.target.value,
                                      });
                                    }}
                                    placeholder=""
                                    aria-describedby="basic-addon2"
                                  />
                                  <InputGroup.Append>
                                    <Button
                                      variant="outline-secondary"
                                      onClick={() =>
                                        this.finaliseSale(
                                          this.state.stakerAddress
                                        )
                                      }
                                      className="bg-theme btn-theme">
                                      Finalize sale
                                    </Button>
                                  </InputGroup.Append>
                                </InputGroup>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

ManageSale.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps)(ManageSale);
