import axios from "axios"

import backurl from "../lib/config.js"


export const getCmslists = async (data) => {
    const resp = await axios({
        method: 'GET',
        url: `${backurl}/cms/cmslist`,
        "headers": {
            "Content-Type": 'application/json'
        },
        data: data,
    })
    console.log(resp.data);
    return resp.data;
}

export const getCmsEdit = async (data) => {
    const resp = await axios({
        method: 'POST',
        url: `${backurl}/cms/cmsedit`,
        "headers": {
            "Content-Type": 'application/json'
        },
        data: data,
    })
    console.log(resp.data);
    return resp.data;
}

export const getcmslistData = async (data) => {
    const resp = await axios({
        method: 'GET',
        url: `${backurl}/cms/cmslistdata`,
        "headers": {
            "Content-Type": 'application/json'
        },
        params: data,
    })
    console.log(resp.data);
    return resp.data;
}

export const getsociallists = async (data) => {
    const resp = await axios({
        method: 'POST',
        url: `${backurl}/cms/addsociallink`,
        "headers": {
            "Content-Type": 'application/json'
        },
        data: data,
    })
    console.log(resp.data);
    return resp.data;
}

export const roadmaplist = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/roadmaplist`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);
        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const hideshowroadmap = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/hideshowroadmap`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const EditRoadmap = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/EditRoadmap`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const AddRoadmap = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/AddRoadmap`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}





export const aboutlist = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/About-list`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);
        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const hideshowabout = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/hideshowAbout`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const Editabout = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/Edit-About`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const Addabout = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/Add-About`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}




export const paradigmlist = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/Paradigm-list`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);
        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const hideshowparadigm = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/hideshow-Paradigm`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const Editparadigm = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/Edit-Paradigm`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const Addparadigm = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/Add-Paradigm`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}




export const philosophylist = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/Philosophy-list`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);
        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const hideshowphilosophy = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/hideshow-Philosophy`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const Editphilosophy = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/Edit-Philosophy`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const Addphilosophy = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/Add-Philosophy`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}







export const charterlist = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/Charter-list`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);
        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const hideshowcharter = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/hideshow-Charter`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const Editcharter = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/Edit-Charter`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const Addcharter = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${backurl}/cms/Add-Charter`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: data,
        })
        console.log(resp.data);

        return resp.data;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

